<template>
	<div>
		<a-spin v-show="!showModal" :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<!-- 搜索栏 -->
				<a-row>
					<a-form-item label="状态" name="status" class="ui-form__item">
						<a-select v-model:value="formState.status" placeholder="请选择状态" allow-clear
							style="width: 180px;">
							<a-select-option :value="1">启用</a-select-option>
							<a-select-option :value="2">禁用</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="时间段">
						<a-range-picker></a-range-picker>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<a-button v-permission="['performance_special_mustSnatch_add']" type="primary" @click="onAdd">新增必抢专场</a-button>
						<!-- <a-button type="primary" @click="showPerformance = !showPerformance">复制演出</a-button> -->
						<!-- <a-button type="primary" style="margin-left: 10px;">批量清空</a-button> -->
					</a-col>
					<a-col :span="6" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div>
				<cTable ref="cTable" :columns="columns" :isPage="true" :searchData="searchData" :isRequest="true" :requestFun="getPerformRecommendList" :scroll="{ x: 1000 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'time'">
							<div>{{ transDateTime(record.startTime) }}</div>
							至
							<div>{{ transDateTime(record.endTime) }}</div>
						</template>
						<template v-if="column.key === 'isDisabled'">
							{{ record.isDisabled ? '已禁用' : '已启用' }}
						</template>
						<template v-if="column.key === 'createTime'">
							{{ transDateTime(record.createTime) }}
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div v-permission="['performance_special_mustSnatch_edit']" @click="onEdit(record)">
											<a-menu-item>
												编辑
											</a-menu-item>
										</div>
										<div v-permission="['performance_special_mustSnatch_disabled']" v-if="record.isDisabled" @click="onChangeState(record)">
											<a-menu-item>
												启用
											</a-menu-item>
										</div>
										<div v-permission="['performance_special_mustSnatch_disabled']" v-else @click="onChangeState(record)">
											<a-menu-item>
												禁用
											</a-menu-item>
										</div>
										<div v-permission="['performance_special_mustSnatch_delete']" @click="onDelete(record)">
											<a-menu-item>
												删除
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</cTable>
			</div>
		</a-spin>
		<div v-if="showModal">
			<temp @back="onBack" :id="id" :isEdit="isEdit"></temp>
		</div>
	</div>
</template>

<script>
	import {Icon} from '@/components/icon/icon.js';
	import temp from './temp.vue';
	import cTable from '@/components/cTable/index.vue';
	import { getPerformRecommendList, disabledPerformRecommend, deletePerformRecommend } from '@/service/modules/perform.js';
	export default {
		components: {
			Icon,
			cTable,
			temp,
		},
		data() {
			return {
				showModal: false,
				isEdit: false,
				isSee: false,
				id: 0,
				loading: false,
				searchData: {},
				formState: {},
				columns: [{
					title: '必抢标题',
					dataIndex: 'title',
				}, {
					title: '生效时间',
					key: 'time'
				}, {
					title: '优先级',
					dataIndex: 'sort'
				}, {
					title: '状态',
					key: 'isDisabled',
				}, {
					title: '创建时间',
					key: 'createTime'
				}, {
					title: '操作',
					key: 'action',
					width: 100,
					fixed: 'right'
				}],
			}
		},
		mounted() {
			this.getData();
		},
		methods: {
			getPerformRecommendList: getPerformRecommendList,
			reset() {
				this.$refs.form.resetFields();
				this.onSearch(true);
			},
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData(true);
			},
			getData(isReset) {
				if (isReset) {
					// 重置分页查询
					this.$refs.cTable.toQuery();
				} else {
					// 不重置分页查询
					this.$refs.cTable.refQuery();
				}
			},
			onBack(isRef) {
				this.showModal = false;
				if (isRef) {
					this.$nextTick(() => {
						this.getData(true);
					})
				}
			},
			onAdd(){
				this.id = 0;
				this.isEdit = false;
				this.showModal = true;
			},
			onEdit(item) {
				this.id = item.id;
				this.isEdit = true;
				this.showModal = true
			},
			onChangeState(item) {
				this.$confirm({
					title: '提示',
					content: `确定${ item.isDisabled ? '启用' : '禁用' }该数据吗？`,
					onOk: async() => {
						this.loading = true;
						let ret = await disabledPerformRecommend({
							id: item.id,
							isDisabled: item.isDisabled ? 0 : 1
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success(`${ item.isDisabled ? '启用' : '禁用' }成功！`);
							this.getData();
						}
					}
				})
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: '确定删除该数据吗？',
					onOk: async()=> {
						this.loading = true;
						let ret = await deletePerformRecommend({
							id: item.id
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('删除成功！');
							this.getData();
						}
					}
				})
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>