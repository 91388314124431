<template>
	<div>
		<Header style="margin-bottom: 20px;" :title="isEdit ? '编辑今日必抢' : '新增今日必抢'" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="modelRef" name="form" :labelCol="{ span: 4 }" :wrapperCol="{ span: 19 }" @finish="onSubmit">
				<a-form-item label="标题" name="title" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-input v-model:value="modelRef.title" placeholder="请输入标题"></a-input>
				</a-form-item>
				
				<a-form-item label="展示时间段" name="time" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-range-picker v-model:value="modelRef.time"></a-range-picker>
				</a-form-item>
				
				<a-form-item label="优先级" name="sort" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-input-number v-model:value="modelRef.sort" :min="0" :precision="0" placeholder="请输入"></a-input-number>
				</a-form-item>
				
				<a-form-item label="状态" name="isDisabled" :rules="[{ required: true, message: '必选项不允许为空' }]">
					<a-radio-group v-model:value="modelRef.isDisabled">
						<a-radio :value="0">启用</a-radio>
						<a-radio :value="1">禁用</a-radio>
					</a-radio-group>
				</a-form-item>

				<a-form-item label="演出" name="performIds" :rules="[{ required: true, message: '必选项不允许为空' }]">
					<a-form-item-rest>
						<selectPerform v-model:value="modelRef.performIds" :selectItems="list" @change="onPerformChange"></selectPerform>
						<div v-if="list.length" style="margin-top: 20px;">
							<cTable :isPage="false" :isRequest="false" :columns="columns" :dataSource="list" :scroll="{ x: 950 }">
								<template #bodyCell="{ column, record, index }">
									<template v-if="column.key === 'posterUrl'">
										<a-image :src="record.posterUrl" style="width: 60px;"></a-image>
									</template>
									<template v-if="column.key === 'isSelfSupport'">
										{{ record.isSelfSupport ? '自营' : '非自营' }}
									</template>
									<template v-if="column.key === 'isDisabled'">
										{{ record.isDisabled ? '已禁用' : '已启用' }}
									</template>
									<template v-if="column.key === 'action'">
										<a-button type="link" danger size="small" @click="onDelete(record, index)">移除</a-button>
									</template>
								</template>
							</cTable>
						</div>
					</a-form-item-rest>
				</a-form-item>
				
				<div style="text-align: center;">
					<a-button type="primary" html-type="submit">提 交</a-button>
					<a-button style="margin-left: 20px;" @click="onBack(false)">返 回</a-button>
				</div>
			</a-form>
		</a-spin>
	</div>
</template>

<script>
	import Header from '@/components/header/header.vue';
	import selectPerform from '@/components/select/selectPerform/index.vue';
	import cTable from '@/components/cTable/index.vue';
	import { savePerformRecommend, updatePerformRecommend, getPerformRecommendDetail } from '@/service/modules/perform.js';
	export default {
		components: {
			Header,
			cTable,
			selectPerform
		},
		props: {
			id: {
				type: Number,
				default: 0
			},
			isEdit: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				showPerformance: false,
				loading: false,
				modelRef: {
					isDisabled: 0,
					performIds: []
				},
				columns: [{
					title: '演出名称',
					dataIndex: 'name'
				}, {
					title: '海报',
					key: 'posterUrl'
				}, {
					title: '演出类型',
					key: 'isSelfSupport'
				}, {
					title: '演出分类',
					dataIndex: 'classifyName'
				}, {
					title: '演出标签',
					dataIndex: 'tagName'
				}, {
					title: '状态',
					key: 'isDisabled'
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right',
					width: 80
				}],
				list: []
			}
		},
		created() {
			if (this.id) {
				this.getData();
			}
		},
		methods: {
			async onSubmit() {
				let postData = this.$deepClone(this.modelRef);
				if (postData.time && postData.time.length) {
					postData.startTime = postData.time[0].startOf('days').unix();
					postData.endTime = postData.time[1].endOf('days').unix();
				}
				delete postData.time;
				this.loading = true;
				let ret;
				if (this.isEdit && this.id) {
					ret = await updatePerformRecommend(postData);
				} else {
					ret = await savePerformRecommend(postData);
				}
				this.loading = false;
				if (ret.code === 200) {
					this.$message.success('操作成功！');
					this.onBack(true);
				}
			},
			async getData() {
				this.loading = true;
				let ret = await getPerformRecommendDetail({
					id: this.id
				})
				this.loading = false;
				if (ret.code === 200) {
					this.list = ret.data.performs;
					ret.data.time = [this.moment(ret.data.startTime * 1000), this.moment(ret.data.endTime * 1000)];
					delete ret.data.performs;
					this.modelRef = ret.data;
				}
			},
			onPerformChange(dataList) {
				this.list = JSON.parse(JSON.stringify(dataList));
				this.$refs.form.validateFields('performIds');
			},
			onDelete(item, index) {
				let i = this.modelRef.performIds.indexOf(item.id);
				this.modelRef.performIds.splice(i, 1);
				this.list.splice(index, 1);
			},
			reset() {
				this.$refs.form.resetFields();
			},
			onBack(isRef) {
				this.$emit('back', isRef);
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>